import { create } from "zustand";
import { createJSONStorage, persist, StateStorage } from "zustand/middleware";

const hashStorage = {
  getItem: (key) => {
    const searchParams = new URLSearchParams(window.location.hash.slice(1));
    const storedValue = searchParams.get(key) ?? "";
    return JSON.parse(storedValue);
  },
  setItem: (key, newValue) => {
    const searchParams = new URLSearchParams(window.location.hash.slice(1));
    searchParams.set(key, JSON.stringify(newValue));
    const { state } = Object.fromEntries(
      [...searchParams].map(([key, value]) => [
        key,
        JSON.parse(JSON.parse(value)),
      ]),
    )?.["global-state"];

    let path;

    if (!!state?.project)
      path = ["project", state?.project]?.filter(Boolean).join("/");

    if (!state?.project) path = [state?.route]?.filter(Boolean).join("/");
    if (!state?.project && !!state?.info) path = "info";

    const newURL = [window.location.origin, path]?.join("/");
    history.replaceState(null, "", newURL);
    // window.location.hash = searchParams.toString();
  },
  removeItem: (key) => {
    const searchParams = new URLSearchParams(window.location.hash.slice(1));
    searchParams.delete(key);
    const { state } = Object.fromEntries(
      [...searchParams].map(([key, value]) => [
        key,
        JSON.parse(JSON.parse(value)),
      ]),
    )?.["global-state"];

    let path;

    if (!!state?.project)
      path = ["project", state?.project]?.filter(Boolean).join("/");

    if (!state?.project) path = [state?.route]?.filter(Boolean).join("/");
    if (!state?.project && !!state?.info) path = "info";

    const newURL = [window.location.origin, path]?.join("/");
    history.replaceState(null, "", newURL);
    // window.location.hash = searchParams.toString();
  },
};

export const useHashStore = create()(
  persist(
    (set) => ({
      view: "index",
      route: null,
      category: null,
      info: false,
      project: null,
      inTransition: false,
      showEffect: false,
      setView: (param) => set((state) => ({ view: param })),
      setRoute: (param) => set((state) => ({ route: param })),
      setCategory: (param) => set((state) => ({ category: param })),
      setInfo: (param) => set((state) => ({ info: param })),
      setProject: (param) => set((state) => ({ project: param })),
      setTransition: (param) => set((state) => ({ inTransition: param })),
      setEffect: (param) => set((state) => ({ showEffect: param })),
    }),
    {
      name: "global-state",
      // storage: createJSONStorage(() => hashStorage),
    },
  ),
);
