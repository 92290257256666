import animateScrollTo from "animated-scroll-to";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import slugify from "slugify";
import { shallow } from "zustand/shallow";

import { DUR_SM, PRESENTATION_MODES } from "@/config";
import { useHashStore } from "@/lib/store";

import Radio from "./Radio";

const Categories = ({ categories, mobile = false, dummy }) => {
  const { setInfo, setView, setCategory, setRoute, view, route, ...hashState } =
    useHashStore((state) => state, shallow);

  const setCat = (category) => {
    const elementToScroll =
      document?.querySelectorAll(".ProjectsList > ul")?.[0];

    if (elementToScroll && route === "list") {
      setTimeout(() => {
        animateScrollTo(0, {
          elementToScroll,
          cancelOnUserAction: false,
          maxDuration: 1000,
        }).then(() => {
          setInfo(false);
          setCategory(category);

          if (route !== "list") {
            setRoute(category === "all" ? "projects" : category);
          } else {
            setRoute("list");
          }
        });
      }, 300);
    } else {
      setInfo(false);
      setCategory(category);

      if (route !== "list") {
        setRoute(category === "all" ? "projects" : category);
      } else {
        setRoute("list");
      }
    }
  };

  const hasCategories =
    !!dummy ||
    (PRESENTATION_MODES?.includes(route) && (!mobile || !hashState?.project));

  const layoutProps = { layout: !mobile };

  return (
    <>
      <motion.section
        className="Categories"
        initial={false}
        animate={
          !dummy && {
            opacity: hasCategories ? 1 : 0,
            width: hasCategories || mobile ? "auto" : "0px",
          }
        }
        transition={{
          delay: 0,
          opacity: {
            delay: !hasCategories
              ? 0
              : mobile
                ? !hashState?.project
                  ? DUR_SM * 2
                  : 0
                : 0,
            duration: DUR_SM,
          },
          duration: mobile ? 0 : DUR_SM,
        }}
        exit={{ opacity: 0 }}
        data-mobile={!!mobile}
        {...layoutProps}
      >
        <motion.ul {...layoutProps}>
          <motion.li
            initial={false}
            animate={{ opacity: !hasCategories ? 0 : 1 }}
            exit={{ opacity: 0 }}
            transition={{
              delay: hashState?.project
                ? 0
                : !hasCategories
                  ? 0
                  : mobile
                    ? 0
                    : DUR_SM,
              duration: mobile ? 0 : DUR_SM,
            }}
            {...layoutProps}
          >
            <button
              onMouseDown={(e) => {
                e.preventDefault();
                setCat("all");
              }}
            >
              <Radio active={hashState?.category === "all"} />
              All
            </button>
          </motion.li>
          <motion.li>
            <ul>
              {categories?.map(({ title, _id }, i) => (
                <motion.li
                  initial={false}
                  animate={{ opacity: !hasCategories ? 0 : 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    delay: hashState?.project
                      ? 0
                      : !hasCategories
                        ? 0
                        : mobile
                          ? 0
                          : (1 + i * 0.5) * DUR_SM,
                    duration: mobile ? 0 : DUR_SM,
                  }}
                  key={_id}
                  {...layoutProps}
                >
                  <button
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setCat(slugify(title, { lower: true }));
                    }}
                  >
                    <Radio
                      active={
                        hashState?.category === slugify(title, { lower: true })
                      }
                    />
                    {title}
                  </button>
                </motion.li>
              ))}
            </ul>
          </motion.li>
        </motion.ul>
      </motion.section>

      <style jsx global>{`
        .Categories {
          display: flex;
          justify-content: center;
          overflow: hidden;
          margin-left: 0 !important;
          will-change: width;
          flex-shrink: 0;
        }
        .Categories[data-mobile="true"] {
          backdrop-filter: unset;
          background: unset !important;
        }

        .Categories :is(a, button) {
          display: block;
          white-space: nowrap;
        }

        .Categories > ul > li:not(:first-child) {
          display: block;
          overflow: auto;
          overscroll-behavior: contain;
        }

        .Categories > ul > li:not(:first-child)::-webkit-scrollbar {
          display: none;
        }

        .Categories > ul {
          max-width: 100%;
          height: 100%;
          display: grid;
          grid-template-columns: auto 1fr;
          gap: calc(var(--dist-md) / 2);
          padding-left: var(--dist-md);
        }

        .Categories[data-mobile="true"] > ul {
          width: 100%;
          background: var(--bg-glass);
          backdrop-filter: blur(3px) brightness(0.9) hue-rotate(120deg);
        }

        .Categories > ul button {
          height: 100%;
        }

        .Categories ul li ul {
          height: 100%;
          display: flex;
          flex-wrap: nowrap;
          gap: calc(var(--dist-md) / 2);
          white-space: nowrap;
        }
      `}</style>
    </>
  );
};

export default Categories;
