
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../global.css";
import { Router, useRouter } from "next/router";
import { groq } from "next-sanity";
import { lazy, useEffect } from "react";
import { shallow } from "zustand/shallow";
import Body from "@/components/global/Body";
import { CATEGORIES, PRESENTATION_MODES } from "@/config";
import useGlobalStore from "@/hooks/useGlobalStore";
import useScrollbarWidth from "@/hooks/useScrollbarWidth";
import { getClient } from "@/lib/sanity.client";
import { useHashStore } from "@/lib/store";
const PreviewProvider = lazy(() => import("@/components/preview/PreviewProvider"));
function App({ Component, pageProps, globalProps, state }) {
    const { draftMode, token } = pageProps || {};
    const { setView, setProject, setInfo, setRoute, setCategory, setTransition } = useHashStore((state) => state, shallow);
    useEffect(() => {
        setInfo(state?.info);
        setView(state?.view);
        setProject(state?.project);
        state?.route && setRoute(state?.route);
        setCategory(state?.category);
    }, []);
    useEffect(() => {
        useGlobalStore.setState((state) => ({ ...state, globalProps })); // Update only the globalProps within the global state
    }, [globalProps]);
    useScrollbarWidth();
    useEffect(() => {
        // Used for page transition
        const start = () => {
            setTransition(true);
        };
        const end = () => {
            setTransition(false);
        };
        Router.events.on("routeChangeStart", start);
        Router.events.on("routeChangeComplete", end);
        Router.events.on("routeChangeError", end);
        return () => {
            Router.events.off("routeChangeStart", start);
            Router.events.off("routeChangeComplete", end);
            Router.events.off("routeChangeError", end);
        };
    }, []);
    return (<>
      <Body {...pageProps} {...globalProps}>
        {draftMode ? (<PreviewProvider token={token}>
            <Component {...pageProps}/>
          </PreviewProvider>) : (<Component {...pageProps}/>)}
      </Body>
    </>);
}
App.getInitialProps = async (ctx) => {
    const { draftMode = false } = ctx;
    const client = getClient();
    const [info, legal, contact, metadata, categories] = await Promise.all([
        client.fetch(groq `*[_type == "info"][0]`),
        client.fetch(groq `*[_type == "legal"][0]`),
        client.fetch(groq `*[_type == "contact"][0]`),
        client.fetch(groq `*[_type == "metadata"][0]`),
        client.fetch(groq `*[_type == "project.category"]{_id, title, description}`),
    ]);
    const slug = ctx?.ctx?.asPath?.split("/")?.[1], sub = ctx?.ctx?.asPath?.split("/")?.[2];
    return {
        state: {
            route: PRESENTATION_MODES.includes(slug)
                ? slug
                : !slug
                    ? "selected"
                    : null,
            view: "index",
            category: CATEGORIES.includes(slug) ? slug : "all",
            project: slug === "project" ? sub : undefined,
            info: slug === "info",
        },
        globalProps: { info, legal, contact, metadata, categories },
    };
};

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  