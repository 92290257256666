import { AnimatePresence } from "framer-motion";
import dynamic from "next/dynamic";
import usePortal from "react-useportal";
import { shallow } from "zustand/shallow";

import Seo from "@/components/shared/Seo";
import { useHashStore } from "@/lib/store";

import Screensaver from "../shared/Screensaver";
import Header from "./Header";

const Project = dynamic(() => import("./Project"), {
  // loading: () => <p>Loading...</p>,
});

const Info = dynamic(() => import("./Info"), {
  // loading: () => <p>Loading...</p>,
});

const Legal = dynamic(() => import("./Legal"), {
  // loading: () => <p>Loading...</p>,
});

const Body = ({
  children,
  info,
  legal,
  contact,
  metadata,
  categories,
  data,
  ...props
}) => {
  const { Portal } = usePortal({
    isOpen: true,
  });
  const { setProject, ...hashState } = useHashStore((state) => state, shallow);

  return (
    <>
      <Seo
        defaultSeo={metadata?.defaultSeo}
        seo={data?.page?.seo}
        title={data?.page?.title}
      />
      <Portal>
        <Header categories={categories}>
          <AnimatePresence mode="wait">
            {!!hashState?.info && <Info info={info} categories={categories} />}
            {!!hashState?.legal && <Legal legal={legal} />}
          </AnimatePresence>
        </Header>
        <Screensaver />
      </Portal>
      {!!hashState?.project && (
        <Project
          slug={hashState?.project}
          isOpen={!!hashState?.project}
          onClose={() => setProject(null)}
        />
      )}
      {children}
    </>
  );
};

export default Body;
