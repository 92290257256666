import { motion } from "framer-motion";
import { shallow } from "zustand/shallow";

import { DUR_SM } from "@/config";
import useWindowSize from "@/hooks/useWindowSize";
import { useHashStore } from "@/lib/store";

import Categories from "../shared/Categories";
import Radio from "../shared/Radio";
import View from "../shared/View";

const Nav = ({ navRef, categories, dummy }) => {
  const { windowW } = useWindowSize();
  const isMobile = windowW < 768;

  const { setInfo, setView, setCategory, setRoute, view, route, ...hashState } =
    useHashStore((state) => state, shallow);

  const set = (view) => {
    setView("index");
    setInfo(false);
    setCategory("all");
    setRoute("selected");
  };

  return (
    <>
      <motion.nav
        initial={false}
        animate={{
          opacity: !hashState?.project ? 1 : 0,
        }}
        transition={{
          duration: DUR_SM,
          delay: DUR_SM,
        }}
        ref={navRef}
        layout
        layoutRoot
        data-dummy={!!dummy}
        className="Nav"
      >
        <motion.button
          onMouseDown={() => {
            set(null);
          }}
          key="home"
          layout
        >
          <Radio active={!route || route === "selected"} />
          Selected
        </motion.button>

        {!isMobile && <Categories categories={categories} dummy={dummy} />}

        <View dummy={dummy} />

        <motion.button
          onMouseDown={() => setInfo(!hashState?.info)}
          key="info"
          layout
        >
          <Radio active={!!hashState?.info} />
          Info
        </motion.button>
      </motion.nav>

      <style jsx global>{`
        .Nav {
          width: auto;
          display: flex;
          flex-shrink: 0;

          justify-content: center;
          height: calc(2 * var(--dist-md) + var(--lh-md));
          padding: 0 calc(var(--dist-md));
          contain: paint style;
          content-visibility: auto;
          contain-intrinsic-size: calc(2 * var(--dist-md) + var(--lh-md));
        }

        .Nav[data-dummy="true"] {
          position: fixed;
          bottom: 0;
          left: 0;
          background: red;
          z-index: -1;
          visibility: hidden;
          pointer-events: none;
        }

        .Nav > *:not(:first-child) {
          margin-left: var(--dist-md);
        }

        .Nav :is(a, button) {
          display: block;
          height: 100%;
        }

        .Nav ~ .Categories {
          width: var(--w-nav) !important;
          margin-left: auto !important;
          height: calc(2 * var(--dist-md) + var(--lh-md));
          align-items: center;
        }
      `}</style>
    </>
  );
};

export default Nav;
