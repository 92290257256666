export const TITLE = "Back of the House";
export const URL = "http://localhost:3000/";
export const CREATED_AT = "2024-02-29";

export const SUPPORTED_LANGUAGES = [
  { id: "en", title: "English", icon: "🇬🇧", isDefault: true },
];

export const MULTILINGUAL = SUPPORTED_LANGUAGES?.length > 1;

export const BASE_LANGUAGE =
  SUPPORTED_LANGUAGES.find((l) => l.isDefault) || SUPPORTED_LANGUAGES?.[0];

// Specify Platform for hosting, deployment, etc.
export const PLATFORM = ["netlify"];

export const PREVIEW_BASE_URL = "/api/draft";

export const SINGLETONS = ["info", "metadata", "contact"];
export const COLLECTIONS = ["project"];

export const PAGES = [...COLLECTIONS];

// Document types which:
// - cannot be created in the 'new document' menu
// - cannot be duplicated, unpublished or deleted
export const LOCKED_DOCUMENT_TYPES = [
  ...SINGLETONS,
  "page",
  "media.tag",
  "translation.metadata",
];

// References to include in 'internal' links
export const PAGE_REFERENCES = PAGES.map((type) => ({
  type,
}));

export const PREVIEWABLE_DOCUMENT_TYPES = ["project"];

export const DUR_XS = 0.1;
export const DUR_SM = 0.3;
export const DUR_MD = 0.7;

export const CATEGORIES = ["show", "retail", "event", "shooting"];
export const PRESENTATION_MODES = ["projects", "list", ...CATEGORIES];
