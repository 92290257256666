import { useRouter } from "next/router";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { shallow } from "zustand/shallow";

import { useHashStore } from "@/lib/store";

const useElementTopOffset = () => {
  const [topOffset, setTopOffset] = useState(0);
  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const updateTopOffset = () => {
      if (elementRef.current) {
        const { top } = elementRef.current.getBoundingClientRect();
        setTopOffset(top);
      }
    };

    updateTopOffset(); // Initial update

    // Update top offset when window is resized
    const handleResize = () => {
      updateTopOffset();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const hashState = useHashStore((state) => state, shallow);
  useEffect(() => {
    const updateTopOffset = () => {
      if (elementRef.current) {
        const { top } = elementRef.current.getBoundingClientRect();
        setTopOffset(top);
      }
    };

    updateTopOffset(); // Initial update
  }, [hashState]);

  return [topOffset, elementRef];
};

const Sentinel = ({}) => {
  const [topOffset, elementRef] = useElementTopOffset();

  return (
    <>
      <div className="Sentinel" ref={elementRef} />
      <style jsx global>{`
        :root {
          --global-offset-y: calc(
            ${topOffset}px - var(--dist-xs) + var(--dist-md)
          );
        }

        .Sentinel {
          margin-bottom: calc(var(--dist-xs) * -1);
        }
      `}</style>
    </>
  );
};

export default Sentinel;
