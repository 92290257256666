import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import { useHashStore } from "@/lib/store";

const Screensaver = ({}) => {
  const [isIdle, setIdle] = useState(false);

  const onIdle = () => {
      setIdle(true);
    },
    onActive = () => {
      setIdle(false);
    },
    { getRemainingTime } = useIdleTimer({
      onIdle,
      onActive,
      timeout: 30000,
      throttle: 100,
      events: ["mousemove", "touchstart"],
    });

  const { showEffect } = useHashStore((state) => state);

  return !isIdle && !showEffect ? null : (
    <>
      <style jsx global>{`
        body {
          background: #000 !important;
          transition: all 0s !important;
        }

        .Media::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #a6b0b9;
          mix-blend-mode: difference;
          opacity: 1;
          z-index: 6;
          pointer-events: none;
          filter: invert(0.05) grayscale(1);
        }

        .Media::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #a6b0b9;
          mix-blend-mode: luminosity;
          opacity: 1;
          z-index: 5;
          pointer-events: none;
        }
      `}</style>
    </>
  );
};

export default Screensaver;
