import { PiRadioButtonFill } from "react-icons/pi";

const Radio = ({ active }) => {
  return (
    <>
      <span className="Radio" data-active={active}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12">
          <path
            fill="currentColor"
            d="M6 1.125a4.875 4.875 0 1 0 0 9.75 4.875 4.875 0 0 0 0-9.75Zm0 9a4.125 4.125 0 1 1 0-8.25 4.125 4.125 0 0 1 0 8.25Z"
          />
          <circle className="dot" cx="6" cy="6" r="3" fill="currentColor" />
        </svg>
      </span>

      <style jsx global>{`
        .Radio {
          display: inline;
          width: 1em;
          height: 1em;
          line-height: 1em;
          position: relative;
          margin-right: 1em;
          margin-left: calc(1em / 2);
        }

        .Radio > * {
          position: absolute;
          top: calc((100% - 1em) / 2);
          left: calc((100% - 1em) / 2);
          width: 1em;
          height: 1em;
        }

        .Radio .dot {
          transition: scale 0.233s;
          transform-origin: center center;
        }

        *:not(:hover) > .Radio:not([data-active="true"]) .dot {
          scale: 0.2;
        }

        @media (hover: hover) {
          *:hover > .Radio:not([data-active="true"]) .dot {
            scale: 0.5;
          }
        }
      `}</style>
    </>
  );
};

export default Radio;
