import { NextSeo } from "next-seo";

import { TITLE } from "@/config";
import { getImgUrl } from "@/lib/sanity.image";

const Seo = ({ title, seo, defaultSeo }) => {
  const {
    index = true,
    follow = true,
    description,
    preview,
  } = { ...defaultSeo, ...seo } || {};

  return (
    <NextSeo
      title={title}
      titleTemplate={`${TITLE} | %s`}
      defaultTitle={TITLE}
      description={description}
      noindex={!index}
      nofollow={!follow}
      openGraph={{
        type: "website",
        title,
        description,
        site_name: TITLE,
        images: !preview
          ? undefined
          : [
              {
                url: getImgUrl(preview),
                width: 1200,
                height: 630,
              },
            ],
      }}
    />
  );
};

export default Seo;
