import { AnimatePresence, motion } from "framer-motion";
import dynamic from "next/dynamic";
import { memo } from "react";
import useMeasure from "react-use-measure";
import slugify from "slugify";
import { shallow } from "zustand/shallow";

import { DUR_SM, PRESENTATION_MODES } from "@/config";
import useHasMounted from "@/hooks/useHasMounted";
import useWindowSize from "@/hooks/useWindowSize";
import { useHashStore } from "@/lib/store";

import Categories from "../shared/Categories";
import Sentinel from "../shared/Sentinel";
import Nav from "./Nav";

const Description = dynamic(() => import("./Description"), {
  // loading: () => <p>Loading...</p>,
});

const Header = ({ categories, children }) => {
  const hasMounted = useHasMounted();

  const { windowW } = useWindowSize();
  const isMobile = windowW < 768;

  const { setInfo, setView, setCategory, setRoute, view, route, ...hashState } =
    useHashStore((state) => state, shallow);

  const [ref, bounds] = useMeasure();

  return !hasMounted ? null : (
    <>
      <motion.header
        className="Header"
        layout
        layoutRoot
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        transition={{
          duration: DUR_SM,
          delay: DUR_SM,
          ease: "easeOut",
        }}
        // transition={{ delay: 0.5, type: "linear" }}
        style={{ "--w-nav": bounds?.width + "px" }}
      >
        <Nav navRef={ref} categories={categories} />
        {PRESENTATION_MODES.includes(route) && isMobile && (
          <Categories categories={categories} mobile key="mobile" />
        )}
        <AnimatePresence mode="wait">
          {PRESENTATION_MODES.includes(route) &&
            categories?.map(
              (category) =>
                slugify(category?.title, { lower: true }) ===
                  hashState?.category && (
                  <Description
                    key={slugify(category?.title, { lower: true })}
                    category={category}
                    w={bounds?.width}
                  />
                ),
            )}
        </AnimatePresence>
        <Sentinel />
        {children}
      </motion.header>
      <style jsx global>{`
        .Header {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100 * var(--svh));
          padding: var(--dist-md);
          z-index: 111;
          display: flex;
          flex-direction: column;
          gap: var(--dist-xs);
          pointer-events: none;
          contain: size layout paint style;
          content-visibility: auto;
          contain-intrinsic-size: calc(100 * var(--svh));
          will-change: all;
        }

        .Header > *:not(.Info) {
          display: flex;
          background: var(--bg-glass);
          border-radius: 1px;
          backdrop-filter: blur(3px) brightness(0.9) hue-rotate(120deg);
          margin: 0 auto;
          pointer-events: auto;
          transition: backdrop-filter 0.4s;
        }

        @media (hover: hover) {
          .Header > *:not(.Info):hover {
            backdrop-filter: blur(3px) brightness(0.6) hue-rotate(120deg)
              contrast(1.2);
          }
        }

        .Header > *:not(.Info):active {
          backdrop-filter: blur(3px) brightness(0.3) hue-rotate(120deg)
            contrast(1.2);
        }
      `}</style>
    </>
  );
};

export default memo(Header);
