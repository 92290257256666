import animateScrollTo from "animated-scroll-to";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { shallow } from "zustand/shallow";

import { DUR_SM, PRESENTATION_MODES } from "@/config";
import { useHashStore } from "@/lib/store";

import Radio from "./Radio";

const View = ({ dummy }) => {
  const {
    setView,
    setInfo,
    setCategory,
    view,
    category,
    route,
    setRoute,
    ...hashState
  } = useHashStore((state) => state, shallow);

  const hasViewOption = !!dummy || PRESENTATION_MODES?.includes(route);

  const set = (v) => {
    setInfo(false);

    if (v === "list") {
      setRoute("list");
    } else {
      const elementToScroll = document?.querySelectorAll(
        ".ProjectsGrid .wrapper",
      )?.[0];

      if (elementToScroll) {
        elementToScroll.style.scrollSnapType = "none";
        setTimeout(() => {
          animateScrollTo(0, {
            elementToScroll,
            cancelOnUserAction: false,
            maxDuration: 1000,
          }).then(() => {
            setView(v);
            if (route !== "projects")
              setRoute(category === "all" ? "projects" : category);

            setTimeout(() => {
              elementToScroll.style.scrollSnapType = "y mandatory";
            }, 500);
          });
        }, 300);
      } else {
        setView(v);
        if (route !== "projects") {
          setRoute(category === "all" ? "projects" : category);
        }
      }
    }
  };

  return (
    <>
      <motion.section className="View" layout>
        <motion.button
          onMouseDown={(e) => {
            e.preventDefault();
            if (hasViewOption) {
              set("index");
            } else {
              setRoute(category === "all" ? "projects" : category);
            }
          }}
        >
          <Radio
            active={hasViewOption && view === "index" && route !== "list"}
          />
          Index
        </motion.button>

        <motion.div
          initial={false}
          animate={{ width: hasViewOption ? "auto" : 0 }}
          transition={{
            delay: 0,
            duration: DUR_SM,
          }}
          key="viewWrapper"
          className="viewContainer"
        >
          <motion.ul className="viewWrapper">
            <motion.li
              className="small"
              key="small"
              initial={false}
              animate={{ opacity: !hasViewOption ? 0 : 1 }}
              exit={{ opacity: 0 }}
              transition={{
                delay: !hasViewOption ? 0 : DUR_SM,
                duration: DUR_SM,
              }}
            >
              <button
                onMouseDown={(e) => {
                  e.preventDefault();
                  set("small");
                }}
              >
                <Radio
                  active={hasViewOption && view === "small" && route !== "list"}
                />
                Small
              </button>
            </motion.li>

            <motion.li
              className="list"
              key="list"
              initial={false}
              animate={{ opacity: !hasViewOption ? 0 : 1 }}
              exit={{ opacity: 0 }}
              transition={{
                delay: !hasViewOption ? 0 : DUR_SM * 1.5,
                duration: DUR_SM,
              }}
            >
              <button
                onMouseDown={(e) => {
                  e.preventDefault();
                  set("list");
                }}
              >
                <Radio active={route === "list"} />
                List
              </button>
            </motion.li>
          </motion.ul>
        </motion.div>
      </motion.section>
      <style jsx global>{`
        .View {
          display: flex;
          justify-content: center;
          overflow: hidden;
        }

        .View :is(a, button, span) {
          white-space: nowrap;
        }

        .View .viewContainer {
          width: 0px;
          will-change: width;
          overflow: hidden;
        }

        .View .viewWrapper {
          display: flex;
          flex-wrap: nowrap;
          gap: calc(var(--dist-md) / 2);
          height: 100%;
        }

        .View .viewWrapper > *:first-child {
          padding-left: calc(var(--dist-md) / 2);
        }
      `}</style>
    </>
  );
};

export default View;
